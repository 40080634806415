<template>
  <div>
    <div class="pc">
      <div>
        <div class="flex-between margin-top-12">
          <div class="body2-bold">전체 문의 {{ allCount }}개</div>
          <button class="button is-primary size-15"
                  style="width:120px;height:36px"
                  @click="openInquiry()">문의하기</button>
        </div>
        <div class="lp-divider-sub5 margin-top-12 margin-bottom-16"></div>

        <!--<div>
          <div class="writeInquire">문의 남기기</div>
          <textarea class="textarea"
                    placeholder="문의글을 작성해주세요."
                    v-model="writeInquiry.question_content"
                    @focus="checkInquiry"
                    maxlength="500"></textarea>
          <div class="flex" style="justify-content: flex-end;margin-top:12px">
            <button class="button is-primary size-15" style="height:35px" @click="saveProductInquiry">문의제출</button>
          </div>
        </div>-->
      </div>
      <div v-for="(inquiry,idx) in themeInquiry"
           :key="'theme-inquiry-'+idx">
        <div @click="clickInquiry(inquiry)">
          <!-- 질문 -->
          <div class="flex-between">
            <div>
              <div class="subtitle5 sub padding-bottom-16" v-if="checkSecret(inquiry)">비밀글입니다.</div>
              <!--<div class="subtitle5 sub ellipsis-1" style="width:970px" v-else>{{ inquiry.title }}</div>-->
              <div class="flex-align">
                              <span class="body5-medium gray3"
                                    :class="{'answered':inquiry.answer_content,'not-answered':!inquiry.answer_content}">
                                {{ inquiry.answer_content?'답변완료':'미답변' }}</span>
                <img src="/static/icon/u_lock-alt.svg" alt="" class="margin-left-8"
                     style="width:20px;height:20px" v-if="inquiry.is_secret">
                <span class="body6 sub2 margin-left-8"><span v-for="(name,idx) in inquiry.author.slice(0,2)" :key="'author-'+idx">{{ name }}</span>*</span>
                <span class="gray1 margin-left-8 margin-right-8">|</span>
                <span class="body5 sub3">{{ datesFormat(inquiry.created_time, 'date_3') }}</span>
              </div>
            </div>
            <!-- 수정 -->
            <div v-if="user.user_id > 0 && !checkSecret(inquiry)">
              <button class="body5-medium button is-primary-light" style="width:66px;height:36px" @click="modifyItem(inquiry, 'pc')">수정</button>
              <button class="body5-medium button is-gray margin-left-8" style="width:66px;height:36px" @click="deleteItem(inquiry, 'pc')">삭제</button>
            </div>
          </div>
          <!-- 답변 -->
          <div class="inquiry-answer-wrapper padding-24 margin-top-16" v-if="!checkSecret(inquiry) || !inquiry.is_secret">
            <div class="subtitle5 flex">
              <div class="sub2">Q</div>
              <div class="body2 sub margin-left-8">{{ inquiry.question_content }}</div>
            </div>
            <div v-if="inquiry.answer_content">
              <div class="subtitle5 flex margin-top-16">
                <div class="primary">RE</div>
                <div class="body2 sub margin-left-8">{{ inquiry.answer_content }}</div>
              </div>
              <div class="body5 sub3 margin-top-16" v-if="inquiry.answered_time">{{ datesFormat(inquiry.answered_time, 'date_3') }}</div>
            </div>
          </div>

          <div class="lp-divider-gray2 margin-top-16 margin-bottom-16"></div>
        </div>
      </div>
      <!-- 페이지네이션 -->
      <lp-paginate :filtered="filtered" class="padding-top-32"></lp-paginate>
    </div>

    <div class="mobile">
      <div class="lp-divider-gray3"></div>
      <div class="container padding-top-24">
        <button class="button body4-medium sub2"
                style="width:100%;height:43px"
                @click="openInquiry()">문의하기</button>
      </div>
      <div class="lp-divider-gray1 margin-top-24"></div>

      <div class="unselect inquiry-wrapper"
           v-for="(inquiry,idx) in themeInquiry"
           :key="'theme-inquiry-'+idx">
        <div @click="clickInquiry(inquiry)">
          <div>
            <!-- 질문 -->
            <div class="flex-between padding-16">
              <div>
                <div class="subtitle5 sub margin-bottom-16" v-if="checkSecret(inquiry)">비밀글입니다.</div>
                <!--<div class="subtitle5 sub ellipsis-1" style="width:200px" v-else>{{ inquiry.title }}</div>-->
                <div class="flex-align">
                                <span class="body5-medium gray3"
                                      :class="{'answered':inquiry.answer_content,'not-answered':!inquiry.answer_content}">
                                  {{ inquiry.answer_content?'답변완료':'미답변' }}</span>
                  <img src="/static/icon/u_lock-alt.svg" alt="" class="margin-left-8"
                       style="width:20px;height:20px" v-if="inquiry.is_secret">
                  <span class="body6 sub2 margin-left-8"><span v-for="(name,idx) in inquiry.author.slice(0,2)" :key="'author-'+idx">{{ name }}</span>*</span>
                  <span class="gray1 margin-left-8 margin-right-8">|</span>
                  <span class="body5 sub3">{{ datesFormat(inquiry.created_time, 'date_3') }}</span>
                </div>
              </div>
              <!-- 수정 -->
              <div v-if="user.user_id > 0 && !checkSecret(inquiry)">
                <button class="body6-medium button is-primary-light" style="width:56px;height:32px" @click="modifyItem(inquiry, 'mobile')">수정</button>
                <button class="body6-medium button is-gray margin-left-8" style="width:56px;height:32px" @click="deleteItem(inquiry, 'mobile')">삭제</button>
              </div>
            </div>

            <!-- 답변 -->
            <div class="inquiry-answer-wrapper padding-24 margin-top-16" v-if="!checkSecret(inquiry) || !inquiry.is_secret">
              <div class="subtitle7 flex">
                <div class="sub2">Q</div>
                <div class="sub margin-left-8">{{ inquiry.question_content }}</div>
              </div>
              <div v-if="inquiry.answer_content">
                <div class="subtitle7 flex margin-top-16">
                  <div class="primary">RE</div>
                  <div class="sub margin-left-8">{{ inquiry.answer_content }}</div>
                </div>
                <div class="body5 sub3 margin-top-16" v-if="inquiry.answered_time">{{ datesFormat(inquiry.answered_time, 'date_3') }}</div>
              </div>
            </div>
          </div>

          <div class="lp-divider-gray2"></div>
        </div>
      </div>
      <!-- 페이지네이션 -->
      <lp-paginate :filtered="filtered" class="padding-top-32"></lp-paginate>
    </div>

    <!-- 문의 등록, 수정 PC -->
    <div class="inquiry-reg-modal-pc">
      <sweet-modal overlay-theme="dark" ref="inquiryRegModal" :width="564"
                   :hide-close-button="true">
        <div class="h7 main padding-bottom-20">문의하기</div>
        <div v-for="(form, form_idx) in formData"
             class="padding-top-16"
             :key="`form-${form_idx}`">
          <div class="subtitle7 text-left margin-bottom-8">
            {{ form.field!=='is_secret'? form.label : '' }} {{ form.validate.required? '*':'' }}
          </div>
          <component :is="form.component_name"
                     class="margin-top-8"
                     v-bind.sync="form"></component>
        </div>
        <div class="flex-between body2-medium" style="margin-top:190px">
          <button class="button" style="width:50%;height:48px;" @click="$refs.inquiryRegModal.close()">취소</button>
          <button class="button is-primary margin-left-16"
                  style="width:50%;height:48px;"
                  @click="saveData('pc')">{{ selectedItem?'수정완료':'등록하기' }}</button>
        </div>
      </sweet-modal>
    </div>

    <!-- 문의 등록, 수정 MOBILE-->
    <div class="inquiry-reg-modal-mobile">
      <sweet-modal overlay-theme="dark" ref="inquiryRegModalMobile" width="343px"
                   :hide-close-button="true">
        <div class="h7 main padding-bottom-20">문의하기</div>
        <div v-for="(form, form_idx) in formData"
             style="padding:16px 16px 0;"
             :key="`form-${form_idx}`">
          <div class="subtitle7 text-left margin-bottom-8">
            {{ form.field!=='is_secret'? form.label : '' }} {{ form.validate.required? '*':'' }}
          </div>
          <component :is="form.component_name"
                     class="margin-top-8"
                     v-bind.sync="form"></component>
        </div>
        <div class="flex-between body2-medium" style="padding:16px 16px 0;margin-top:57px">
          <button class="button" style="width:50%;height:48px;" @click="$refs.inquiryRegModalMobile.close()">취소</button>
          <button class="button is-primary margin-left-16"
                  style="width:50%;height:48px;"
                  @click="saveData('mobile')">{{ selectedItem?'수정완료':'등록하기' }}</button>
        </div>
      </sweet-modal>
    </div>

    <div class="inquiry-reg-modal-pc">
      <sweet-modal overlay-theme="dark" ref="inquiryDeleteModal" :width="564"
                   :hide-close-button="true">
        <div class="body1" style="padding:48px 0">문의내역을 삭제하시면 복구할 수 없습니다. <br> 삭제하시겠습니까?</div>
        <div class="flex-between body2-medium" style="padding:16px 16px 0">
          <button class="button" style="width:50%;height:48px;" @click="$refs.inquiryDeleteModal.close()">취소</button>
          <button class="button is-primary margin-left-16"
                  style="width:50%;height:48px;"
                  @click="deleteData('pc')">삭제하기</button>
        </div>
      </sweet-modal>
    </div>

    <div class="inquiry-delete-modal-mobile">
      <sweet-modal overlay-theme="dark" ref="inquiryDeleteModalMobile" width="343px"
                   :hide-close-button="true">
        <div class="body4">문의내역을 삭제하시면 복구할 수 없습니다. <br> 삭제하시겠습니까?</div>
        <div class="flex-between body5-medium" style="padding:16px 16px 0;margin-top:22px">
          <button class="button" style="width:50%;height:36px;" @click="$refs.inquiryDeleteModalMobile.close()">취소</button>
          <button class="button is-primary margin-left-16"
                  style="width:50%;height:36px;"
                  @click="deleteData('mobile')">삭제하기</button>
        </div>
      </sweet-modal>
    </div>

    <product-inquiry-modal ref="inquiryModal" :inquiryId="selectedItem ? selectedItem.id : undefined"
                          @update="getProductInquiry"></product-inquiry-modal>
  </div>
</template>
<script>
  import LpText from "../component/LpText";
  import LpTextArea from "../component/LpTextArea";
  import LpCheckBoxArray from "../component/LpCheckBoxArray";
  import LpPaginate from "../component/LpPaginate";
  import { SweetModal } from 'sweet-modal-vue'
  import FormMixin from "../../mixins/FormMixin";
  import UserAPIMixin from '../../mixins/UserAPIMixin';
  import ServiceAdminAPIMixin from '../../mixins/ServiceAdminAPIMixin';
  import ProductInquiryModal from "../modal/ProductInquiryModal";
  export default {
    name: "ProductInquiry",
    props: {
    },
    mixins: [
      FormMixin,
      UserAPIMixin,
      ServiceAdminAPIMixin
    ],
    components: {ProductInquiryModal, LpPaginate, SweetModal, LpText, LpTextArea, LpCheckBoxArray},
    created() {
      this.setForm();
      if(this.$route.query.id) {
        this.getProductInquiry();
      }
    },
    data() {
      return {
        formData: [],
        // 문의 목록
        themeInquiry: [],
        // 문으 필터값
        filtered: {
          cur_page: 1,
          page_num:1,
          total_page:1,
          page_length: 9
        },
        // 전체 카운트 수
        allCount: 0,
        selectedItem: undefined
      }
    },
    methods: {
      setForm() {
        this.formData = [
          /*{
            label: '제목',
            placeholder: '제목을 입력해주세요.',
            maxLength: 30,
            field: 'title',
            type: 'text',
            value: '',
            component_name: 'lp-text',
            errorMsg: '',
            validate: {
              num: true,
              alpha_g: true,
              alpha_s: true,
              special: true,
              max_length: 30,
              min_length: 1,
              valid_required: true,
              required: true
            }
          },*/
          {
            label: '내용',
            placeholder: '내용을 입력해주세요.',
            maxLength: 10000,
            field: 'question_content',
            type: 'text',
            value: '',
            component_name: 'lp-text-area',
            errorMsg: '',
            validate: {
              num: true,
              alpha_g: true,
              alpha_s: true,
              special: true,
              max_length: 10000,
              min_length: 1,
              valid_required: true,
              required: true
            },
          },
          {
            label: '비밀글',
            items: [
              {
                label: '비밀글로 작성합니다.',
                labelStyle: {
                  fontSize: 16,
                  lineHeight: '15px',
                  color: '#555'
                }
              }
            ],
            field: 'is_secret',
            type: 'check',
            value: false,
            component_name: 'lp-check-box-array',
            errorMsg: '',
            validate: {
              valid_required: false
            },
          }
        ]
      },
      openInquiry() {
        /*if(!this.isLogin) {
          this.toast('로그인 후 작성하실 수 있습니다.');
          return;
        }*/
        this.selectedItem = undefined;
        /*this.setForm();
        if(type==='pc') this.$refs.inquiryRegModal.open();
        else this.$refs.inquiryRegModalMobile.open();*/
        this.$refs.inquiryModal.open();
      },
      checkSecret(inquiry) {
        if(this.user.user_id > 0) {
          return inquiry.secret;
        } else {
          return inquiry.is_secret;
        }

      },
      // 상품 문의 리스트
      getProductInquiry() {
        let url = `/product/${this.$route.query.id}/inquiry?secret=true`;
        if(this.user.user_id>0) {
          this.request.user.get(url, { params: this.filtered }).then(res=>{ this.getRes(res) });
        } else if(this.$store.getters.admin.id>0) {
          this.request.serviceAdmin.get(url, { params: this.filtered }).then(res=>{ this.getRes(res) });
        } else {
          url = `public/launchpack/theme/${this.$route.query.id}/inquiry`;
          this.$axios.get(url, { params: this.filtered }).then(res=>{ this.getRes(res) });
        }
      },
      getRes(res) {
        this.themeInquiry = res.data.data;
        this.filtered.page_length = res.data.page_length;
        this.filtered.cur_page = res.data.cur_page;
        this.filtered.total_page = res.data.total_page;
        this.allCount = res.data.count;
      },
      clickInquiry(inquiry) {
        if(inquiry.answer_content) inquiry.selected?inquiry.selected=false:inquiry.selected=true
      },
      searchInquiry(e) {
        this.filtered.search_keyword = e.target.value;
        this.getProductInquiry();
      },
      // 문의 제출
      saveData(device) {
        let user_id = this.$store.getters.user.user_id;
        let admin_id = this.$store.getters.admin.id;
        if(user_id === 0 && admin_id === 0) {
          this.toast('로그인하시면 문의글을 남길수 있습니다.');
          return;
        }
        let result = this.getParamData(this.formData);
        if(result.enable) {
          let params = this.cloneItem(result.params);
          params.product_id = this.$route.query.id;
          params.channel = '플랫폼파이';
          if(this.selectedItem) {
            let url = `/product/${this.$route.query.id}/inquiry/${this.selectedItem.id}`;
            if (user_id > 0) {
              this.request.user.patch(url, params).then(res => {
                this.saveInquiry(device, res, 'patch');
              })
            } else if (admin_id > 0) {
              this.request.serviceAdmin.patch(url, params).then(res => {
                this.saveInquiry(device, res, 'patch');
              })
            }
          } else {
            let url = `/product/${this.$route.query.id}/inquiry`;
            if (user_id > 0) {
              this.request.user.post(url, params).then(res => {
                this.saveInquiry(device, res, 'post');
              })
            } else if (admin_id > 0) {
              this.request.serviceAdmin.patch(url, params).then(res => {
                this.saveInquiry(device, res, 'post');
              })
            }
          }
        }
      },
      saveInquiry(device, res, type) {
        if (res.status === 200) {
          if (type === 'patch') {
            this.toast('문의가 수정되었습니다.');
          } else {
            this.toast('문의 등록되었습니다.');
          }
          if(device==='pc') this.$refs.inquiryRegModal.close();
          else this.$refs.inquiryRegModalMobile.close();
          this.getProductInquiry();
        }
      },
      deleteData(device) {
        let user_id = this.user.user_id;
        let admin_id = this.$store.getters.admin.id;
        let url = `/product/${this.$route.query.id}/inquiry/${this.selectedItem.id}`;
        if (user_id > 0) {
          this.request.user.delete(url).then(res => {
            this.deleteInquiry(device, res);
          })
        } else if (admin_id > 0) {
          this.request.serviceAdmin.delete(url).then(res => {
            this.deleteInquiry(device, res);
          })
        }
      },
      deleteInquiry(device, res) {
        if(res.status===200) {
          this.toast('문의가 삭제되었습니다.');
          if(device==='pc') {
            this.$refs.inquiryDeleteModal.close();
          } else {
            this.$refs.inquiryDeleteModalMobile.close();
          }
          this.getProductInquiry();
        }
      },
      modifyItem(inquiry, device) {
        this.selectedItem = inquiry;
        this.formData.forEach(item=>{
          item.value = this.selectedItem[item.field];
        })

        if(device==='pc') {
          this.$refs.inquiryRegModal.open();
        } else {
          this.$refs.inquiryRegModalMobile.open();
        }
      },
      deleteItem(inquiry, device) {
        this.selectedItem = inquiry;
        if(device==='pc') {
          this.$refs.inquiryDeleteModal.open();
        } else {
          this.$refs.inquiryDeleteModalMobile.open();
        }
      },
    }
  }
</script>
<style lang="stylus" scoped>
  @import '~assets/css/lp_main'
  .response-box
    width 100%
    border 1px solid $gray1
    border-radius 3px
    margin-top 16px
    padding 16px

  .admin-response-box
    padding 20px 40px
    color $main

  .admin-response-box .user-name
    color $primary

  .answer-box
    width 67px
    height 32px
    border-radius 3px
    background-color #f7f7f7
    display inline-block
    text-align center
    line-height 32px

  .answer1
    width 67px
    height 32px
    border-radius 3px
    background-color #f7f7f7
    font-size 12px
    color #a0a0a0

  .answer2
    background-color #ffffff
    color #0ead9d
    border 1px solid #e6e6e6
    font-size 12px
    width 67px
    height 32px

  .answer3
    background-color #ffffff
    color #0ead9d
    border 1px solid #e6e6e6
    font-size 12px
    width 67px
    height 32px

  .answered
  .not-answered
    padding 3px 8px
    border-radius 4px

  .answered
    background-color $primary-light2
    color $primary

  .not-answered
    background-color $gray3
    color $sub2

  .inquiry-answer-wrapper
    background-color $gray4
    border-radius 8px

  .selected
    color $primary

  .lp-divider-gray3
    border-bottom 12px solid $gray3
</style>
<style lang="stylus">
  .inquiry-delete-modal-mobile .sweet-modal.is-mobile-fullscreen
  .inquiry-reg-modal-mobile .sweet-modal.is-mobile-fullscreen
    top 50% !important
    left 50% !important
    transform translate(-50%, -50%) !important
  .inquiry-reg-modal-mobile .sweet-modal.is-mobile-fullscreen
    height 512px
  .inquiry-delete-modal-mobile .sweet-modal.is-mobile-fullscreen
    height 182px

  .inquiry-reg-modal-mobile .sweet-content
    padding-top 24px !important

  .inquiry-delete-modal-mobile .sweet-content
    padding-top 48px !important

  .inquiry-delete-modal-pc .sweet-content
  .inquiry-reg-modal-pc .sweet-content
    padding-top 32px !important

  .inquiry-delete-modal-mobile .sweet-content
    padding-bottom 12px !important
</style>
