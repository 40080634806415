<template>
  <div>
    <a :id="anchor" ref="anchor" class="position-relative" :style="`top:${anchorTop}`"></a>
    <div class="flex-align">
      <div class="tab-head unselect" v-for="item in menu" :key="'tab-head-'+item.id"
           :class="{'tab-head-selected': selectedItem === item.id}" @click="selectedItem=item.id">{{ item.name }}</div>
    </div>
    <template v-for="item in menu">
      <slot :name="'tab'+item.id" v-if="selectedItem === item.id"></slot>
    </template>
  </div>
</template>

<script>
  export default {
    name: "Tab",
    props: {
      menu: {
        type: Array,
        required: true
      },
      anchor: {
        type: String,
        default: 'tabs'
      },
      anchorTop: {
        type: String
      }
    },
    created() {
      this.selectedItem = this.menu[0].id;
    },
    data() {
      return {
        selectedItem: 0
      }
    },
    methods: {}
  }
</script>

<style lang="stylus" scoped>
  @import '~assets/css/lp_main'

  .tab-head
    background-color $gray4
    padding 12px
    border-bottom 1px solid $gray1
    color $sub3
    font-size 16px
    font-weight 500
    flex 1
    text-align center
  .tab-head-selected
    color $primary
    border 1px solid $gray1
    border-bottom 0
    background-color white

  @media (max-width: 500px)
    .tab-head
      background-color white
      padding-bottom 13px
      border-bottom 1px solid $gray2
      font-size 14px

    .tab-head-selected
      border 0
      padding-bottom 12px
      border-bottom 2px solid $primary

</style>
