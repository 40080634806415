export default {
  data() {
    return {
      selectorStocks: []
    }
  },
  mounted() {
    this.getSelectorStocks();
  },
  methods: {
    // 추가 옵션 여부
    isAddOption(order_product_option) {
      let item = this.findItem(order_product_option, 'option_type', 1);
      return !!item;
    },
    getSelectorStocks() {
      if(this.order && this.order.order_product && this.order.order_product.product_id) {
        let url = `public/product/${this.order.order_product.product_id}/selector_stocks`
        this.$axios.post(url).then(res=>{
          this.selectorStocks = res.data.stocks;
        });
      }
    },
    isSubscription(stock, product) {
      let enable = false;
      // 상품 옵션 사용할 경우
      if (stock && product.enable_option) {
        let item = this.findItem(this.selectorStocks, 'id', stock.option_id)
        if (item) {
          enable = item.options.some(option_id => {
            for (let i = 0; i < product.selectors.length; i++) {
              let selector = product.selectors[i];
              for (let j = 0; j < selector.options.length; j++) {
                let option = selector.options[j];
                if (option.id === option_id && option.is_subscription) {
                  return true
                }
              }
            }
          })
        }
      }
      return enable;
    },
    optionName(option) {
      let name = '';
      let stock = this.findItem(this.selectorStocks, 'id', option.option_id)
      if(stock) name = stock.name;
      let word = '(견적서 발송 예정)';
      if (name && name.indexOf(word) > -1) {
        name = `<span>${name.replace(word, '')} <span style="color:#FF3B3B">${word}</span>`;
      }
      if (name.indexOf('플랜없음 x ')>-1 || name.indexOf(' x 플랜업음')) {
        name = name.replace('플랜없음 x ', '')
        name = name.replace(' x 플랜업음', '')
      }
      return name;
    },
    optionPrice(option) {
      let price = 0;
      let stock = this.findItem(this.selectorStocks, 'id', option.option_id)
      if(stock) {
        price = stock.price;
      }
      return price;
    },
  }
}
